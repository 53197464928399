angular
    .module('annexaApp')
    .component('annexaBoxDocumentsPaginated',{
        templateUrl: './components/doc/annexa-box-documents-paginated/annexa-box-documents-paginated.html',
        controller:['$q', 'DccumentsFactory', '$rootScope', 'AnnexaObjectBoxFactory', '$scope', 'Language', '$filter', 'AnnexaFormlyFactory', 'GlobalDataFactory', 'DialogsFactory', 'AnnexaEntityFactory', 'TramNewFactory', 
		function($q, DccumentsFactory, $rootScope, AnnexaObjectBoxFactory, $scope, Language, $filter, AnnexaFormlyFactory, GlobalDataFactory, DialogsFactory,AnnexaEntityFactory, TramNewFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASDocuments') {
                    var id = undefined;
                    
                    if(args.selected && args.selected.length > 0){
                    	vm.box.search.searchedValue = { id: id, object: args.selected };
                        vm.box.search.completeAdd();
                    }
                    args.modal.close();
                }
            });

            this.$onInit = function() {
				if(vm.box && vm.box.tableDefinition){
	                vm.docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray();
					vm.box.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(
	                    vm.box.permissions,
	                    'new_document',
	                    'view_documents',
	                    vm.box.isEdit
	                );
	
	                if(!vm.box.boxTitle) {
	                    vm.box.boxTitle = 'global.literals.documents';
	                }
	                if(!vm.box.search) {
	                    vm.box.search = {};
	                }
	                vm.box.search.addedSelect = 'x => x.document.id';
	                vm.box.search.origin = 'doc';
	                vm.box.search.type = 'Document';
					vm.box.search.additionalFilter = {
						paginated: true,
	                	paginatedObject: vm.box.tableDefinition.objectIdToSelectType,
	                	paginatedObjectId: vm.box.tableDefinition.objectIdToAdded
	                }
					if(vm.box.tableDefinition.addToAdditionalFilter){
						var keys = Object.keys(vm.box.tableDefinition.addToAdditionalFilter);
                        _.forEach(keys, function(key){
                        	vm.box.search.additionalFilter[key] = vm.box.tableDefinition.addToAdditionalFilter[key];
                        });
					}
	                vm.box.search.processSearch = function (object) {
	                    return object.name;
	                }
	                if(!vm.box.search.completeAdd) {
	                    vm.box.search.completeAdd = function() {
	                    	var newContent = [];
	                    	if(vm.box.search.searchedValue && vm.box.search.searchedValue.object && Array.isArray(vm.box.search.searchedValue.object)){
	                    		_.forEach(vm.box.search.searchedValue.object, function(obj){
	                    			newContent.push({document: JSOG.decode(obj), relationType: 'DOCUMENT'});
	                    		});
	                    	}else{
	                    		newContent.push({document: vm.box.search.searchedValue.object, relationType: 'DOCUMENT'} );
	                    	}
	
	                		vm.box.search.searchedValue = { id: -1, value: '' };
	                        if(vm.box.search.saveSearch ) {
	                            vm.box.search.saveSearch(newContent);
	                        }
	                    }
	                }
	
	                vm.box.search.advancedSearch = function() {
	                	 var docStatusRender = function(data, type, full, meta) {
	                         var content = '';
	
	                         if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
	                             var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");
	                             if(status) {
	                                 content += '<div class="text-center">';
	                                 content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
	                                 content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
	                                 content += '    </span>';
	                                 content += '</div>';
	                             }
	                         }
	                         return content;
	                     }
	                    var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;
	                    var includeRegister = null;
	                    if(vm.box.config && vm.box.config.module && vm.box.config.module == 'REGSALIDA'){
	                    	includeRegister = true;
	                    }
	                    var advancedSearchModal = {
	                        title: 'global.literals.advanced_search_documents',
	                        size: 'modal-lg',
	                        filter: [
	                            { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
	                            { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
	                            { id: 'csv', type: 'text', order: 2, label: 'global.documents.new.csv' },
	                            { id: 'internalDoc', type: 'checkbox', order: 3, label: 'global.documents.internalDocument', callAux: true, model: true },
	                            { id: 'template', type: 'select', order: 0, label: 'global.literals.template', dataType: 'LOCAL', data: $linq(GlobalDataFactory.templates).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: 'description', userChoosen: true },
	                            { id: 'section', type: 'select', order: 1, label: 'global.literals.section', dataType: 'LOCAL', data: $linq(GlobalDataFactory.sections).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: vm.languageColumn, userChoosen: true },
	                            { id: 'type', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray(), addAll: true, nameProperty: vm.languageColumn, userChoosen: true },
	                            { id: 'origin', type: 'select', order: 3, label: 'global.literals.module', dataType: 'LOCAL', data: GlobalDataFactory.docModules, addAll: true, nameProperty: vm.languageColumn, callAux: true, userChoosen: true },
	                            { id: 'createUser', type: 'text', order: 4, label: 'global.literals.user', callAux: true, userChoosen: true },
	                            { id: 'meta', type: 'text', order: 5, label: 'global.literals.meta', callAux: true, userChoosen: true },
	                            { id: 'modifyDdate', type: 'dateRange', order: 6, label: 'global.literals.modified', callAux: true, userChoosen: true },
	                            { id: 'dossierNumber', type: 'text', order: 7, label: 'global.literals.numExp', callAux: true, userChoosen: true },
	                            { id: 'docStatus', type: 'select', order: 8, label: 'global.literals.status', callAux: true, dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentStatuses).orderBy("x => x.orderStatus").toArray(), addAll: true, nameProperty: 'nameLiteral', userChoosen: true },
	                            { id: 'related', type: 'checkbox', order: 9, label: 'global.literals.showRelateds', callAux: true, preFilter:true, negated:false}
	                        ],
	                        tableDefinition: {
	                            id: 'tableAdvancedSearchDocuments',
	                            origin: 'doc',
	                            objectType: 'Document',
	                            sortName: 'name',
	                            sort: [[3, 'asc']],
	                            columns: [
	                            	{ id: 'docStatus', width: '10%', title: $filter('translate')('global.literals.status'), render: docStatusRender, sortable: false },
	                                { id: 'type', width: '10%', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', vm.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
	                                { id: 'id', width: '10%', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.id; if(full.code) { code = full.code.substr((full.code.indexOf(full.id) - 2), full.id.toString().length + 2); } code;') },
	                                { id: 'id', column: new UIRouterStateColumnNoLink($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', 'name')  },
	                                { id: 'createUser', width: '20%', column: new UserColumn($filter, 'global.literals.user') },
	                                { id: 'modifyDate', width: '20%', column: new DateTimeColumn($filter, 'global.literals.modifyDate') }
	                            ], 
	                            noLoading: true,
	                            skipFirstSearch: true,
	                            callAuxToAdd: {paginated:true, paginatedObject:vm.box.tableDefinition.objectIdToSelectType, paginatedObjectId:vm.box.tableDefinition.objectIdToAdded, includeRegisterEntry: includeRegister }
	                        },
	                        multiple: true
	                    }
	
	                    AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASDocuments', advancedSearchModal);
	                }
	                
	                if(!vm.box.new) {
	                    vm.box.new = {};
	                }
	
	                vm.box.new.newFunc = function() {
	                    var preCloseModal = function(document) {
	                        vm.box.search.searchedValue = {
	                            id: document.id, value: document.name,
	                            object: {
	                                id: document.id,
	                                docStatus: document.docStatus,
	                                name: document.name,
	                                profiles: document.profiles,
	                                code: document.code,
	                                type: document.type
	                            }
	                        };
	                        vm.box.search.completeAdd();
	                    }
						if(vm.box && vm.box.config && vm.box.config.module === 'EXPED' && vm.box.config.transaction && vm.box.config.transaction.dossier){
                    		var extra = {
                            	transactionType: vm.box.config.transaction.transactionType,
                            	proposalSubtype: vm.box.config.transaction.proposalSubtype,
                            	processProfiles: vm.box.config.transaction.processProfiles
                            }
		                    DccumentsFactory.openNewDocument(undefined, preCloseModal, vm.box.config.module, vm.box.config.documentTitle, undefined, vm.box.config.transaction.dossier.archiveClassification, undefined, extra, undefined, undefined, vm.box.config.requiredAndCannotCreateDocument, vm.box.config.transaction.dossier.section);
        	            }else{
		                    DccumentsFactory.openNewDocument(undefined, preCloseModal, vm.box.config.module, vm.box.config.documentTitle, undefined, undefined, undefined, undefined, undefined, undefined, vm.box.config.requiredAndCannotCreateDocument)
						}
	                }
	
	                if(vm.box.new.multiple) {
	                    var self = this;
	
	                    var preCloseModalFunction = function() {
	                        _.forEach(this.wizard.steps[this.wizard.steps.length - 1].annexaFormly.fields[0].data.documents, function(item) {
	                            if(item.procState == 'complete') {
	                                vm.box.search.searchedValue = {
	                                    id: item.docData.id, value: item.docData.name,
	                                    object: {
	                                        id: item.docData.id,
	                                        docStatus: item.docData.docStatus,
	                                        name: item.docData.name,
	                                        profiles: item.docData.profiles,
	                                        code: item.docData.code,
	                                        type: item.docData.type
	                                    }
	                                };
	                                vm.box.search.completeAdd();
	                            }
	                        });
	                    }
	
	                    vm.box.new.newMultipleFunc = function() {
							if(vm.box && vm.box.config && vm.box.config.module){
								vm.module = vm.box.config.module;
							}
							if(vm.box && vm.box.config && vm.box.config.module === 'EXPED' && vm.box.config.transaction && vm.box.config.transaction.dossier){
 								DccumentsFactory.openMasiveDocumentModalFolder(preCloseModalFunction, self, vm.box.config.transaction.dossier.archiveClassification, JSOG.encode(vm.box.config.transaction), undefined, vm.box.config.transaction.dossier.section);
							}else{
		                        DccumentsFactory.openMasiveDocumentModalFolder(preCloseModalFunction, self);
							}
	                    }
	
	                    if(vm.box.new.multiple.scanner && $rootScope.app.configuration.load_documents_from_scan_folder.value) {
	                        vm.box.new.newMultipleScannerFunc = function () {
								if(vm.box && vm.box.config && vm.box.config.module){
									vm.module = vm.box.config.module;
								}
								if(vm.box && vm.box.config && vm.box.config.module === 'EXPED' && vm.box.config.transaction && vm.box.config.transaction.dossier){
									DccumentsFactory.openMasiveDocumentModalScan(preCloseModalFunction, self, vm.box.config.transaction.dossier.archiveClassification, JSOG.encode(vm.box.config.transaction));
								}else{
		                            DccumentsFactory.openMasiveDocumentModalScan(preCloseModalFunction, self);
								}
	                        }
	                    }
	                }
	                vm.box.new.showNewInteroperable = false;
	                if($rootScope.app.configuration.add_document_via_oberta != null){
	                	vm.box.new.showNewInteroperable = $rootScope.app.configuration.add_document_via_oberta.value;
	                }
	                vm.box.new.newInteroperable = function(){
	                    var self = this;
	                    var commitModal = function(documents) {
							if(documents && documents.length > 0){
								var docs = [];
								_.forEach(documents, function (doc) {
		                            docs.push({
	                                    id: doc.id,
	                                    docStatus: doc.docStatus,
	                                    name: doc.name,
	                                    profiles: doc.profiles,
	                                    code: doc.code,
	                                    type: doc.type
                                	});
		                        });
								vm.box.search.searchedValue = {
	                                id: docs[0].id, value: docs[0].name,
	                                object: docs
	                            };
	                            vm.box.search.completeAdd();
							}
	                    }
						self.archiveClassification = vm.box.config.transaction.dossier.archiveClassification;
						//TODO
	                    var thirds = undefined;
	                    if(vm.registerEntry){
	                    	thirds = vm.registerEntry.thirds;
		                    DccumentsFactory.openNewInteroperbleModal(self, thirds, commitModal, vm.box.config.module, undefined);
	                    } else if(vm.box.config.transaction && vm.box.config.transaction.dossier && vm.box.config.transaction.dossier.id) {
							TramNewFactory.getDossierThirds(vm.box.config.transaction.dossier.id).then(function (data) {
								if(vm.box && vm.box.config && vm.box.config.module){
									vm.module = vm.box.config.module;
								}
								DccumentsFactory.openNewInteroperbleModal(self, ((data)?JSOG.decode(data):[]), commitModal, vm.module, JSOG.encode(vm.box.config.transaction));
							}).catch(function (data) {
	                    		
			           		});
	                    }
	                }
					if(vm.box.new.showAssociateThirdDocuments){
						vm.box.new.associateThirdDocuments = function(){
							var promises = [];
							promises.push(TramNewFactory.getDossierThirds(vm.box.config.transaction.dossier.id));
		                	promises.push(TramNewFactory.getThisDossierTransactionDocuments(vm.box.config.transaction.id));
			                $q.all(promises).then(function(data) {
								DccumentsFactory.associateThirdDocuments(((data && data[1])?JSOG.decode(data[1]):[]), ((data && data[0])?JSOG.decode(data[0]):[]), vm.box.origin);
			                }).catch(function() {
				            })
						}
					}
					
					if(vm.box.new.template){
						vm.box.new.fromTemplate = function () {
							var promises = [];
							promises.push(TramNewFactory.getDossierThirds(vm.box.config.transaction.dossier.id));
		                	if(vm.box.config.transaction.dossier && vm.box.config.transaction.dossier.procedure && vm.box.config.transaction.dossier.procedure.guided){
								promises.push(TramNewFactory.getProcedureCreateTransactionTypeTemplatesByDossierTransactionId(vm.box.config.transaction.id));
							}else{
								promises.push(TramNewFactory.getProcedureCreateTransactionTypeTemplates(vm.box.config.transaction.dossier.procedure.id, vm.box.config.transaction.id, vm.box.config.transaction.transactionType.id));
							}
			                $q.all(promises).then(function(data) {
								var executeModal = true;
								if(data && data[1] && data[1].length == 1 && data[1][0].id == -1){
									executeModal = false;
								}
								if(executeModal){
			                		AnnexaEntityFactory.showNewDocumentFromTemplateModal(vm.box.config.transaction, new SortedArray(TramNewFactory.TramTemplates, vm.languageColumn).sort(), new SortedArray(GlobalDataFactory.documentTypes, vm.languageColumn).sort(), vm, undefined, undefined, JSOG.decode(data[0]), JSOG.decode(data[1]), vm.box.config.requiredAndCannotCreateDocument);
								}else{
									DialogsFactory.error($filter('translate')('global.errors.noTemplatesSpel'));
								}
			                }).catch(function() {
				            })
                    	}
					}
	
				}
            }
        }],
        bindings: {
            box: '='
        }
    })